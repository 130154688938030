@media (min-width: 992px) and (max-width: 1199px) {
  main #search form fieldset {
    min-height: 60px !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  main #search form fieldset {
    min-height: 60px !important;
  }
}
@media (max-width: 767px) {
  header .logo img {
    margin: 0 auto;
  }
  header nav {
    float: none;
  }
  header nav li {
    float: none;
  }
  header nav li a {
    border-right: 0;
    display: block;
    border-bottom: 1px solid #eeeeee;
    text-align: center;
    padding: 5px 0;
  }
  header .sub-nav {
    float: none;
  }
  header .sub-nav li {
    float: none;
  }
  header .sub-nav li a {
    border-right: 0;
    display: block;
    text-align: center;
    padding: 5px 0;
    font-size: 13px;
  }
  header .sub-nav .last {
    padding-right: 10px;
  }
  main #search form .left {
    padding-right: 15px;
  }
  main #search form .left fieldset {
    border-right-width: 1px;
  }
  main #search form .right {
    padding-left: 15px;
  }
}
@media print {
  header,
  footer,
  .btn {
    display: none;
  }
  a[href]:after {
    content: "";
  }
}
/*# sourceMappingURL=_/css/main.css.map */